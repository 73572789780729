<template>
    <video loop ref="bg" autoplay
        style="width: 100%; height: 100%; object-fit: cover; position: absolute; top: 0; bottom: 0; left: 0; right: 0; overflow: hidden; z-index:0; background-size: 100% 100%; background-position: center;"
        :style="{ backgroundImage: bgImg ? `url(${bgImg})` : '' }" muted>
        <source :src="bgVideo" type="video/mp4">
        Seu navegador não suporta a reprodução de vídeo.
    </video>


    <div
        style="position: relative; height: 100%; width: 100%; display: flex; justify-content: center; align-items: center;">
        <div id="cdg_wrapper" ref="canvas_wrapper" class="" style="background-color: transparent !important;">
            <canvas id="canvas" ref="canvas" :style="{ marginBottom: vkopen && vkheight ? vkheight + 'px' : '0' }"></canvas>
        </div>


        <div :class="this.mode === 'karaoke' ? 'controls_wrapper_karaoke_mode' : 'controls_wrapper'"
            style="color: #fff">

            <div class="controls_title" style="color: #fff"
                v-if="this.$store.state.currentSong && this.mode !== 'karaoke'">
                {{
                    this.$store.state.currentSong[2] }} - {{
                    this.$store.state.currentSong[0] }}</div>

            <div class="controls_left_wrapper" v-if="this.player && this.mode !== 'karaoke'">
                <div class="play-button controls-button" title="Iniciar" @click="startSong()" v-if="!this.playing">
                    <i class="fa fa-play" style="color: #fff"></i>
                </div>


                <div class="pause-button controls-button" title="Pausar" @click="pauseSong()" v-if="this.playing">
                    <i class="fa fa-pause" style="color: #fff"></i>
                </div>

                <div class="next-button controls-button" title="Parar" @click="nextSong()">
                    <i class="fa fa-step-forward" style="color: #fff"></i>
                </div>


            </div>

            <div id="controls_timeline" ref="controls_timeline" class="controls_timeline">
                <div class="timeline_current_time" v-if="this.mode != 'karaoke'">{{ this.formatTime(this.currentTime) }}
                </div>
                <div ref="timeline_wrapper" class="timeline_wrapper" @click="timelineClick">
                    <div class="timeline_progress" :style="{ width: timelineProgressWidth }">
                    </div>
                </div>
                <div class="timeline_track_length" v-if="this.mode != 'karaoke'">{{ this.formatTime(this.trackLength) }}
                </div>
            </div>


            <div class="controls_right_wrapper" v-if="this.player && this.mode !== 'karaoke'">
                <div class="rate-button" title="Velocidade">
                    <div class="player-popup popup-rate">Vel
                        <v-slider direction="vertical" :min="0.5" :max="2" step=".25" show-ticks="always"
                            thumb-label="always" :thumb-size="10" tick-size="1" v-model="rate"
                            @update:model-value="(val) => { setRate(val) }"></v-slider>
                    </div>
                    <i class="fa fa-tachometer" style="color: #fff"></i>
                </div>
                <div class="pitch-button" title="Semitons">
                    <div class="player-popup popup-pitch">Tom
                        <v-slider direction="vertical" :min="-12" :max="12" step="1" show-ticks="always"
                            thumb-label="always" :thumb-size="10" tick-size="1" v-model="pitch"
                            @update:model-value="(val) => { setPitch(val) }"></v-slider>
                    </div>
                    <i class="fa fa-music" style="color: #fff"></i>
                </div>
                <div class="volume-button controls-button" title="Volume">
                    <div class="player-popup popup-volume" v-if="!mute">Vol
                        <v-slider direction="vertical" :min="0" :max="100" :step="5" show-ticks="always"
                            thumb-label="always" style="max-height: 100px !important;" tick-size="1" :thumb-size="10"
                            v-model="volumeSliderValue"
                            @update:model-value="(val) => { setVolume(val / 100) }"></v-slider>
                    </div>
                    <i class="fa" style="color: #fff" @click="this.toggleMute()"
                        :class="{ 'fa-volume-off': volume === 0 || mute, 'fa-volume-down': volume > 0 && volume < 1 && !mute, 'fa-volume-up': volume === 1 && !mute }"></i>
                </div>
            </div>

        </div>



        <div class="trial-notice" v-if="this.trial">
            <div style="flex: 1; padding: 0 15px; color: #fff">
                Você está usando uma <b>versão demonstrativa do KaraokeFlix</b> com limitação de 1 minuto por música.
                Assine um de nossos planos para remover esta mensagem e ter
                <b>acesso a todas as +17 mil músicas completas.</b>
            </div>
            <div style="padding: 0 15px;">
                <button class="btn btn-square" type="button"
                    style="color: #333; background-color: #fff; border-radius: 4px;" @click="this.$router.push(
                        { name: 'plans' }
                    )">Ver Planos</button>
            </div>
        </div>



    </div>
</template>

<script>

/* import './jszip.min.js';
import JSZipUtils from './jszip-utils.min.js';
import './jsmediatags.min.js'; */
import Backgrounds from '../../data/backgrounds';

import { mapState } from 'vuex';
import KaraokeflixPlayer from './karaokeflixplayer.js';
//const KaraokeflixPlayer = require('./karaokeflixplayer.js'); // Caminho relativo ou adequado


import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({ position: 'bottom', maxToasts: 1, queue: false });

export default {
    name: 'Cdgplayer',
    emits: ['interface'],
    data() {
        return {
            bgImg: '',
            bgVideo: this.randomBGWaiting(),
            bgPlaying: false,
            player: null,
            controls: null,
            statusChanged: null,
            pitch: 0,
            volume: 1,
            rate: 1,
            playing: false,
            mute: false,
            trial: false,
            canvasWrapperWidth: 0,
            canvasWrapperHeight: 0,
            currentTime: 0,
            trackLength: 0,
            timelineProgressWidth: 0,
            rndBG: null,
            vkopen: false,
            vkheight: 0,
        };
    },
    props: {
        song: {
            type: String,
            required: false,
        },
        mode: {
            type: String,
            required: false,
        },
    },
    computed: {
        /* songlist: function () {
          var songlist = this.$store.state.songlist;
          this.tags = this.loadTags(songlist.songs || []);
          this.selectionTags = this.loadTags(songlist.selections || []);
          return songlist;
        }, */
        ...mapState({
            shortcuts: (state) => state.shortcuts,
        }),
        volumeSliderValue: {
            get() {
                return this.volume * 100; // Converte de 0-1 para 0-100
            },
            set(value) {
                this.volume = value / 100; // Converte de 0-100 para 0-1
            },
        },
    },
    watch: {
        song(newValue, oldValue) {
            // Este é o watcher para a prop 'valorProp'
            console.log(`Novo valor da prop song: ${newValue}`);
            console.log(`Valor anterior da prop song: ${oldValue}`);

            this.loadCdgplayer(newValue);
            // Coloque aqui as ações que você deseja realizar quando a prop muda
        }
    },
    mounted() {

        console.log('mounted !!! ');
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);
        //document.addEventListener('keydown', this.handleKeyPress);
        try {
            if (this.song) {
                this.loadCdgplayer(this.song);
            }
        }
        catch (e) {
            console.log(e, 'e')
        }


    },
    beforeUnmount() {
        this.destroyPlayer();
        // Realizar ações de limpeza, remover event listeners, etc.
        //document.removeEventListener('keydown', this.handleKeyPress);
        window.removeEventListener('resize', this.updateDimensions);

    },
    methods: {
        virtualKeyboardUpdated(opened, vkheight) {
            this.vkopen = opened;
            this.vkheight = vkheight;
            this.updateDimensions();
        },
        async updateDimensions() {


            // Obtenha as dimensões da div
            this.canvasWrapperWidth = this.$refs.canvas_wrapper.clientWidth;
            this.canvasWrapperHeight = this.$refs.canvas_wrapper.clientHeight;


            // Corrigir limpeza do canva ao redimensioná-lo
            const ctx = this.$refs.canvas.getContext("2d");
            var blob = null;
            var imageBitmap = null;
            if (this.$refs.canvas.width > 0 && this.$refs.canvas.height > 0) {
                blob = await new Promise((resolve) => this.$refs.canvas.toBlob(resolve));
                imageBitmap = await createImageBitmap(blob);
            }


            if (this.vkopen) {
                // Atualize dimensões com teclado virtual aberto.
                this.$refs.canvas.height = (this.canvasWrapperHeight - this.vkheight) * .85;
                this.$refs.canvas.width = (this.canvasWrapperHeight - this.vkheight) * .85 * 1.333; //4:3

            }
            else {
                // Atualize as dimensões do canvas
                this.$refs.canvas.height = this.canvasWrapperHeight * .85;
                this.$refs.canvas.width = this.canvasWrapperHeight * .85 * 1.33; //4:3
            }


            // Corrigir limpeza do canva ao redimensioná-lo
            if (imageBitmap && this.$refs.canvas.width > 0 && this.$refs.canvas.height > 0) {
                ctx.drawImage(imageBitmap, 0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
            }

        },
        randomBGWaiting() {
            var rnd = Math.floor(Math.random() * Backgrounds.waiting.length);
            return Backgrounds.waiting[rnd].url;
        },
        randomBGCdg() {
            //var rnd = Math.floor(Math.random() * Backgrounds.cdg.length);
            //return Backgrounds.cdg[rnd].url;
            var rnd = Math.floor(Math.random() * Backgrounds.cdg.length);
            return Backgrounds.cdg[rnd].url;
        },
        /* handleKeyPress(event) {

            const pressedKey = event.key.toString().toUpperCase();
            console.log('pressedKey CDG:', pressedKey, event.shiftKey);

            switch (pressedKey) {
                case "ESCAPE": this.escapeHome(); break;
            }

            if (this.shortcuts === true && this.player) {

                
                switch (pressedKey) {
                    
                    case "B": this.updateCdgBackground(); break;

                    case "ENTER": this.playSong(); break;
                    case "Q": this.stopSong(); break;
                    case "R": this.togglePlay(); break;
                    case "W": this.restartSong(); break;
                    case "M": this.volumeUp(); break;
                    case "N": this.volumeDown(); break;
                    //case "A": this.pitchUp(); break;
                    //case "V": this.pitchDown(); break;
                }

            }
        }, */
        escapeHome() {
            this.$router.push({ name: 'home' })
        },
        pitchUp() {

            if (this.player) {
                var curPitch = this.pitch;
                var newPitch = curPitch >= 12 ? 12 : curPitch + 1;
                this.player.changeKey(newPitch);
                this.pitch = newPitch;

                toaster.clear();

                if (this.mode === 'karaoke') toaster.success(`${(newPitch > 0 ? '+' : '') + newPitch + ' semitons'}`);
            }



        },
        pitchDown() {

            if (this.player) {
                var curPitch = this.pitch;
                var newPitch = curPitch <= -12 ? -12 : curPitch - 1;
                this.player.changeKey(newPitch);
                this.pitch = newPitch;

                toaster.clear();
                if (this.mode === 'karaoke') toaster.success(`${(newPitch > 0 ? '+' : '') + newPitch + ' semitons'}`);
            }
        },
        pitchReset() {

            if (this.player) {
                this.player.changeKey(0);
                this.pitch = 0;

                toaster.clear();


                if (this.mode === 'karaoke') toaster.success(`0 semitons`);
            }

        },

        rateUp() {
            if (this.player) {
                var newRate = this.rate >= 2 ? 2 : this.rate + 0.25
                newRate = Math.round(newRate * 100) / 100;
                this.setRate(newRate);
                this.rate = newRate;
                toaster.clear();
                if (this.mode === 'karaoke') toaster.success(`Velocidade ${newRate}x`);
            }
        },
        rateDown() {
            if (this.player) {
                var newRate = this.rate <= 0.5 ? 0.5 : this.rate - 0.25
                newRate = Math.round(newRate * 100) / 100;
                this.setRate(newRate);
                this.rate = newRate;
                toaster.clear();
                if (this.mode === 'karaoke') toaster.success(`Velocidade ${newRate}x`);
            }
        },

        volumeUp() {
            if (this.player) {
                var curVolume = parseFloat(this.player.getVolume())
                var newVolume = curVolume >= 1 ? 1 : curVolume + 0.05
                //newVolume = Math.round(newVolume);
                newVolume = Math.round(newVolume * 100) / 100;
                this.setVolume(newVolume);
                this.volume = newVolume;

                toaster.clear();


                if (this.mode === 'karaoke') toaster.success(`Volume ${Math.round(newVolume * 100)}%`);

            }

        },
        volumeDown() {

            if (this.player) {
                var curVolume = parseFloat(this.player.getVolume())
                var newVolume = curVolume <= 0 ? 0 : curVolume - 0.05
                newVolume = Math.round(newVolume * 100) / 100;
                this.setVolume(newVolume);
                this.volume = newVolume;

                toaster.clear();


                if (this.mode === 'karaoke') toaster.success(`Volume ${Math.round(newVolume * 100)}%`);
            }

        },
        toggleMute() {
            if (this.player) {
                this.player.toggleMute();
                this.mute = !this.mute;

                toaster.clear();


                if (this.mode === 'karaoke' && this.mute === true) toaster.success(`Mudo`);
            }

        },



        callSong(url) {
            if (this.player) this.destroyPlayer();
            this.loadCdgplayer(url);
        },

        playSong() {
            console.log('playSong');
            if (this.player) this.player.start();
        },
        startSong() {
            console.log('startSong');
            if (this.player) this.player.start();
        },
        pauseSong() {
            console.log('pauseSong');
            if (this.player) this.player.pause();
        },
        stopSong() {
            console.log('stopSong');
            clearTimeout(this.loadTimeout);

            //this.player.changePlayerPosition(0);
            if (this.player) this.player.seek(0);
            if (this.player) this.player.pause();
            if (this.player) this.player.stop();

            if (this.mode === 'karaoke') {
                this.bgVideo = this.randomBGWaiting();
                this.$refs.bg.load();
                this.$emit('interface', 'nextSong')
            }

            /* setTimeout(() => {
                if (this.mode === 'karaoke') {
                    this.bgVideo = this.randomBGWaiting();
                    this.$refs.bg.load();
                    this.$emit('interface', 'nextSong')
                }
            }, 100) */
        },
        restartSong() {
            console.log('restartSong');
            //this.player.changePlayerPosition(0);
            setTimeout(() => {
                if (this.player) this.player.seek(0);
            }, 100)
        },
        togglePlay() {
            console.log('togglePlay');
            if (this.player) this.player.togglePlay();
        },
        destroyPlayer() {
            console.log('destroyPlayer cdg');
            if (this.player) this.player.destroy();
            this.player = null;
            this.controls = null;
        },
        setRate(val) {
            if (this.player) this.player.changeRate(val);
        },
        setPitch(val) {
            if (this.player) this.player.changeKey(val);
        },
        setVolume(val) {
            if (this.player) this.player.setVolume(val);
        },
        nextSong() {
            this.$emit('interface', 'nextSong')
        },
        /* previousSong() {
            this.$emit('interface', 'previousSong')
        }, */
        updateCdgBackground() {
            //this.bgVideo = this.randomBGCdg();
            this.rndBG = this.randomBGCdg();

            if (this.rndBG.includes(".mp4")) {
                this.bgVideo = this.rndBG;
                this.bgImg = '';
            } else if (this.rndBG.includes(".jpg")) {
                this.bgVideo = '';
                this.bgImg = this.rndBG;
            }

            this.$refs.bg.load();
        },
        loadCdgplayer(filename) {



            console.log('loadCdgplayer ---> ', filename);


            this.updateCdgBackground();

            this.$emit('interface', 'showNextSong')

            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            this.player = new KaraokeflixPlayer(canvas, ctx);

            /* this.controls = new CDGControls('#cdg_controls', this.player, {
                position: 'bottom',
            }); */

            this.player.on('loaded', this.handleLoaded);
            this.player.on('playing', this.handlePlaying);
            this.player.on('ending', this.handleEnding);
            this.player.on('timeupdate', this.handleTimeUpdate);

            /* this.statusChanged = this.player.props.on('status', (val) => {
                console.log('Status: ', val, this.player);
                if (val === 'File Loaded' && this.player) {
                    this.player.start();
                }
            });

            var playing = this.player.props.on('loaded', (val) => {
                console.log('loaded: ', val, this.player);
                this.$store.dispatch('setLoading', false)
            });

            var playing = this.player.props.on('isPlaying', (val) => {
                console.log('isPlaying: ', val, this.player);

                if (val === true) {
                    this.$emit('interface', 'playing')
                    //this.$refs.bg.play();
                } else {
                    this.$emit('interface', 'stoping')
                    //this.$refs.bg.pause();
                }

            });

            var playing = this.player.props.on('timePlayed', (val) => {

                if (this.player) {
                    console.log('timePlayed: ', val, this.player.props.trackLength, this.player);

                    if (val === this.player.props.trackLength) {

                        //this.nextSong()
                        if (this.$route.path === '/karaoke') {
                            this.$emit('interface', 'finishedSong')
                            this.destroyPlayer();

                            this.bgVideo = this.randomBGWaiting();
                            this.$refs.bg.load();
                        }
                        else {
                            this.nextSong()
                        }

                    }
                }

            }); */



            if (this.$route.path === '/karaoke') {
                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.$emit('interface', 'hideIntro')
                    this.$emit('interface', 'hideNextSong')
                    this.pitch = 0;
                    this.rate = 1;
                    this.player.load(filename, this.volume, this.pitch, this.rate)
                }, 5000)
            }
            else {
                this.pitch = 0;
                this.rate = 1;
                this.player.load(filename, this.volume, this.pitch, this.rate)
            }


            /* const player = new CDGPlayer('#cdg_video_wrapper');
            console.log('CDGPlayer', CDGPlayer);
            
            const myFilePath = '';
            player.load(myFilePath);
            player.play(); */
        },
        handleLoaded(data) {
            this.$store.dispatch('setLoading', false)
            console.log('handleLoaded', data, this.checkForReload());
            if (data.status === 'loaded') {
                this.status = 'Audio and CDG loaded successfully!';
                this.trackLength = data.trackLength;

                this.playSong();

                /* if (!this.checkForReload() || this.mode === 'karaoke') {
                    this.playSong();
                } */
            } else {
                this.status = `Failed to load audio and CDG: ${data.error}`;
            }
        },
        handlePlaying(data) {
            this.playing = data.playing;
            console.log('handlePlaying', data.playing);
            if (data.playing) { this.$emit('interface', 'playing') }
            else { this.$emit('interface', 'paused') };
        },
        handleEnding(data) {
            console.log('handleEnding', data);
            this.playing = false;
            if (data.ending) {
                //this.nextSong()
                if (this.$route.path === '/karaoke') {
                    this.$emit('interface', 'finishedSong')
                    this.destroyPlayer();

                    this.bgVideo = this.randomBGWaiting();
                    this.$refs.bg.load();
                }
                else {
                    this.nextSong()
                }
            }
        },
        handleTimeUpdate(data) {
            this.currentTime = data.currentTime;
            this.timelineProgressWidth = this.calculateProgressTimelineWidth(this.currentTime);
            //console.log('handleTimeUpdate', this.timelineProgressWidth)
        },

        checkForReload() {
            return !document.referrer || document.referrer === window.location.href;
        },
        formatTime(time) {
            time = Math.floor(time);
            const minutes = Math.floor(time / 60);
            const left = time % 60;
            return `${minutes}:${left.toString().padStart(2, '0')}`;
        },
        calculateProgressTimelineWidth(time) {
            return `${((this.currentTime / this.trackLength) * 100).toFixed(2)}%`; // Calcula a porcentagem
        },
        timelineClick(event) {

            if (this.player) {
                const rect = event.target.getBoundingClientRect();
                const divWidth = rect.width; // Largura da div
                const clickX = event.clientX - rect.left; // Posição do clique em pixels dentro da div
                const percentage = (clickX / divWidth) * 100; // Calcula a porcentagem
                console.log('timelineClick', divWidth, clickX, percentage);
                this.timelineProgressWidth = `${percentage.toFixed(2)}%`;

                const time = this.trackLength * (percentage / 100);
                this.player.seek(time);
            }
        }
    },
    setup() {
        return {

        };
    },
};
</script>

<style>
#cdg_wrapper {
    height: 100%;
    max-height: 100%;
    aspect-ratio: 300 / 216;
    margin: auto auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

#cdg_wrapper canvas {
    filter: drop-shadow(2px 2px 2px #000);
    /* height: 75%;
    aspect-ratio: 300 / 216 */
}


.controls_wrapper {
    display: flex;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%) !important;
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    padding: 15px 30px 15px 30px;
    flex-wrap: wrap;
}


.controls_title {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;

}

.controls_left_wrapper {
    width: auto;
    height: 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 20px;
}

.controls_right_wrapper {
    height: 40px;
    display: flex;
    justify-content: flex-end;
    /*space-evenly;*/
    align-items: center;
    font-size: 20px;
}






#cdg_controls {
    flex: 1
}

.cdg-controls {
    height: 40px !important;
}


.cdg-controls span {
    font-size: 12px !important;
}

.cdg-controls .playButton,
.cdg-controls .pitch,
.cdg-controls .volumeControl {
    display: none !important;
}



.volume-button,
.pitch-button,
.rate-button {
    position: relative;
    width: 50px;
    text-align: center
}


.player-popup {
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 2px rgba(0, 0, 0, .5);
}


.controls-button {
    margin: 0;
    width: 50px;
    text-align: center;
}



.popup-rate {
    position: absolute;
    opacity: 0;
    width: 50px;
    height: 160px;
    bottom: 60px;
    right: 0;
    transition: all .3s ease;
    border-radius: 8px;
    padding: 15px;
    font-size: 11px;
    font-weight: 600;
}

.rate-button:hover .popup-rate {
    opacity: 1;
    bottom: 30px;
}

.popup-rate .v-slider-thumb__label::after {
    content: 'x'
}


.popup-pitch {
    position: absolute;
    opacity: 0;
    width: 50px;
    height: 160px;
    bottom: 60px;
    right: 0;
    transition: all .3s ease;
    border-radius: 8px;
    padding: 15px;
    font-size: 11px;
    font-weight: 600;
}

.pitch-button:hover .popup-pitch {
    opacity: 1;
    bottom: 30px;
}


.v-slider.v-input--vertical .v-slider-track__background,
.v-slider.v-input--vertical .v-slider-track__fill {
    visibility: hidden !important;
}


.popup-volume .v-slider-thumb__label::after {
    content: '%'
}





.popup-volume {

    position: absolute;
    opacity: 0;
    width: 50px;
    height: 160px;
    bottom: 60px;
    right: 0;
    transition: all .3s ease;
    border-radius: 8px;
    padding: 15px;
    font-size: 11px;
    font-weight: 600;

}

.volume-button:hover .popup-volume {
    opacity: 1;
    bottom: 30px;
}



.v-slider.v-input--vertical>.v-input__control {
    min-height: 100px !important;
}




.cdg-controls .progress-container progress {
    height: 5px !important;
    border-radius: none !important;
    transition: all 0.5s ease;
}

.cdg-controls .progress-container progress:hover {
    height: 10px !important;
}


.cdg-controls .progress-container {
    display: flex !important;
    justify-content: center;
    align-items: center;
}



.controls_timeline {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    z-index: 9999;
}


.timeline_wrapper {
    background: rgba(0, 0, 0, .5) !important;
    width: 100%;
    height: 3px;
    margin: 0;
    transition: height .5s;
    border-radius: 4px;
    overflow: hidden;
}

.timeline_progress {
    background: #c00 !important;
    width: 0;
    height: 100%;
    pointer-events: none;
}

.timeline_wrapper:hover {
    height: 10px;
}


.timeline_current_time,
.timeline_track_length {
    padding: 0 15px;
}








.controls_wrapper progress::-webkit-progress-value {
    background: #c00 !important;
}

.controls_wrapper progress::-webkit-progress-bar {
    background: rgba(0, 0, 0, .5) !important;
}



.controls_wrapper_karaoke_mode {
    display: flex;
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: flex-end;
    padding: 0;
    flex-wrap: wrap;
}


.controls_wrapper_karaoke_mode .playControl,
.controls_wrapper_karaoke_mode .timePlayed,
.controls_wrapper_karaoke_mode .trackLength,
.controls_wrapper_karaoke_mode span {
    display: none !important;
}


.controls_wrapper_karaoke_mode .cdg-controls {
    height: auto !important;
}

.controls_wrapper_karaoke_mode .progress-container {
    margin: 0 !important;
}



.controls_wrapper_karaoke_mode .cdg-controls .progress-container progress {
    height: 3px !important;
}

.controls_wrapper_karaoke_mode progress::-webkit-progress-bar {
    background: transparent !important;
    border-radius: 0 !important;
}

.controls_wrapper_karaoke_mode progress::-webkit-progress-value {
    border-radius: 0 !important;
}



.trial-notice {
    z-index: 9999;
    position: absolute;
    bottom: 100px;
    left: 50%;
    width: 66%;
    /* background-color: rgba(165, 65, 65, .6); */
    background-color: rgba(0, 0, 0, .6);
    padding: 22px 32px;
    border-radius: 8px;
    transform: translateX(-50%);
    font-size: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}





@media(max-width: 991px) {


    .timeline_wrapper {
        /* display: none !important; */
    }

    #cdg_wrapper {
        height: auto;
        width: 100%;

    }

    .controls_wrapper {
        padding: 10px 10px 0 10px;
    }

    .controls_title {
        font-size: 15px;
    }

    .controls-button {
        width: 35px;
        font-size: 15px;
    }

    .controls_timeline {
        font-size: 12px;
    }

}
</style>